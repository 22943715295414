import React, { Component } from "react";

export class Contact extends Component {
  render() {
    return (
      <div>
        <div id="contact">
        <h3><strong>Endereço:</strong> <br/> <br/> R. Tiradentes, 193 - Santa Terezinha, São Bernardo do Campo - SP, 09780-000, Brasil</h3>
       {/* <GoogleApiWrapper/> 
       */}
                <div className="social">
                <h3><strong>Conheça nossas Redes Sociais:</strong></h3>
                  <ul>
                    <li>
                      <a
                        href={this.props.data ? this.props.data.facebook : "https://www.facebook.com/AcademiaArtesMarciaisBrasil/"}
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href={this.props.data ? this.props.data.instagram : "https://instagram.com/artesmarciaisbr"}>
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                    <a href="mailto:contato@artesmarciaisbrasil.com">
                      <i className="fa fa-envelope"></i>
                    </a>
                    </li>
                    <li>
                      <a href={this.props.data ? this.props.data.youtube : "https://www.youtube.com/watch?v=583JdSlwbNE&list=PLGFD7Hrv2w2_zmCZ_ue1sm6ZwE_ORiJsU&ab_channel=YakkoSideratos"}>
                        <i className="fa fa-youtube"></i>
                      </a>
                    </li>
                  </ul>
          </div>
        </div>
        <div id="footer">
          <div className="container text-center">
            <p>
              &copy; 2021 Artes Marciais Brasil. Design by{" "}
              <a href="https:github.com/cassiolm" rel="nofollow">
                @cassiolm
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
