import React, { Component } from "react";
import logo from "../components/amb_logo.png";
import whatsapp from "../components/whatsapp.png";

export class Navigation extends Component {
  render() {
    return (
      <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <a className="navbar-brand page-scroll" href="#page-top">
              <img src={logo} alt="logo AMB"/>
            </a>
        <div className="container">
        
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              {" "}
              <span className="sr-only">Toggle navigation</span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
            </button>
          </div>
          <div
            className="collapse navbar-collapse"
            id="bs-example-navbar-collapse-1"
          >
            <ul className="nav navbar-nav navbar-right">
              <li>
                <a href="#features" className="page-scroll">
                  MODALIDADES
                </a>
              </li>
              <li>
                <a href="#about" className="page-scroll">
                  SOBRE
                </a>
              </li>
              <li>
                <a href="#services" className="page-scroll">
                  HORÁRIOS
                </a>
              </li>
              <li>
                <a href="#team" className="page-scroll">
                  EQUIPE
                </a>
              </li>
              <li>
                <a href="#contact" className="page-scroll">
                  CONTATO
                </a>
              </li>
             {/* <li>
                <a href="https://alunos.artesmarciaisbrasil.com/blog/" target="_blank" className="page-scroll">
                  BLOG
                </a>
              </li>*/}
              <li>
                <a href="https://alunos.artesmarciaisbrasil.com/aulaonline/" target="_blank" className="page-scroll">
                 AULA ONLINE
                </a>
              </li>
              <li>
                <a href="https://alunos.artesmarciaisbrasil.com" target="_blank" className="page-scroll">
                  PORTAL
                </a>
              </li>
              <li>
                <a href="https://api.whatsapp.com/send?phone=+5511954528296&text=Ol%C3%A1,%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20as%20aulas%20de%20artes%20marciais!" target="_blank" className="page-scroll">
                  FALE CONOSCO
                  <img src={whatsapp} alt="Whatsapp" className="zap"/>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navigation;
