import React, { Component } from "react";

export class Services extends Component {
  render() {
    return (
      <div id="services" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Agenda de Horários</h2>
            <p>
              Confira os nossos horários de aulas durante a semana.
            </p>
          </div>
          
          <div> 
          <div id="content">
  <ul class="timeline">
    <li class="event" data-date="Wing Chun">
      <p>Segunda-feira: 20H00 às 21H30 <br/>
       Quarta-feira: 20H00 às 21H30 <br/>
        Sábado: 08H00 às 10H00 </p>
      </li>
    <li class="event" data-date="Jeet Kune Do">
      <p>Terça-feira: 19H00 às 20H25 <br/>
       Quinta-feira: 19H às 20H30 <br/>
      </p></li>
    <li class="event" data-date="Judô">
      <p>Segunda-feira: 18H45 às 20H00 <br/>
       Quarta-feira: 18H45 às 20H00 <br/> 
       Sexta-feira: 18H45 às 20H00 <br/> </p>
       <p>Segunda-feira (Infantil): 17H45 às 18H40 <br/>
       Quarta-feira (Infantil): 17H45 às 18H40<br/></p>
    </li>
    <li class="event" data-date="Ninjutsu">
      <p>Quinta-feira: 20H30 às 22H00 <br/> </p>
      <p>Sábado: 10H00 às 12H00 <br/> </p>
    </li>
    <li class="event" data-date="Jow Ga">
      <p>Terça-feira: 17H30 às 19H00 <br/> </p>
    </li>   
  </ul>
</div>

          </div>
        </div>
      </div>
    );
  }
}

export default Services;
